<template>
  <div class="signup-content">
    <div class="signup-form">
      <strong class="form-title">Reset Password</strong>
      <div v-if="!showSuccess">
        <p class="form-subtitle">Enter your email to reset your password</p>
        <form @submit.prevent>
          <label class="input-wrap">
            <input v-model.trim="email" class="input" type="email" />
            <span class="label">your@email.com</span>
          </label>
        </form>
        <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
        <div class="forgot-pwd">
          <a @click="$emit('close')" class="close">Back to Log In</a>
        </div>
        <div class="submit-wrap">
          <input @click="resetPassword()" type="submit" value="Reset Password">
        </div>
      </div>
      <p v-else>Success! Check your email for a reset link.</p>
    </div>
  </div>
</template>

<script>
import { auth } from '@/firebase'

export default {
  data() {
    return {
      email: '',
      showSuccess: false,
      errorMsg: '',
    }
  },
  methods: {
    async resetPassword() {
      // reset logic
      this.errorMsg = ''

      try {
        await auth.sendPasswordResetEmail(this.email)
        this.showSuccess = true
      } catch (err) {
        this.errorMsg = err.message
      }
    }
  }
}
</script>