<template>
  <section class="signup">
    <div class="row">
        <div class="col">
            <div class="intro">
                <div class="logo">
                    <a href="/">
                        <img src="/images/slack-logo.svg" alt="fe-tools">
                    </a>
                </div>
                <p>Welcome to FETOOLS a powerful app to store snippets, notes and mixins for your future projects.</p>
            </div>
        </div>
        <div class="col">
            <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()"></PasswordReset>
            <div v-else class="signup-content">
                <form v-if="showLoginForm" @submit.prevent class="signup-form">
                    <strong class="form-title">Welcome back</strong>
                    <label class="input-wrap">
                        <input v-model.trim="loginForm.email" class="input" type="email">
                        <span class="label">Email</span>
                    </label>
                    <label class="input-wrap">
                        <input v-model.trim="loginForm.password" class="input" type="password">
                        <span class="label">Password</span>
                    </label>
                    <div class="forgot-pwd">
                        <a @click="togglePasswordReset()">Forgot password</a>
                        <a v-if="showCreateAccount" @click.prevent="toggleForm()">Create an Account</a>
                    </div>
                    <div class="error" v-if="errors.length">
                      <div v-for="(error, index) in errors" :key="`error-${index}`" v-html="error"></div>
                    </div>
                    <div class="submit-wrap">
                        <input type="submit" @click="login()" value="Sign in">
                    </div>
                </form>
                <form v-else @submit.prevent class="signup-form">
                  <strong class="form-title">Get Started</strong>
                  <label class="input-wrap">
                    <input v-model.trim="signupForm.name" type="text" class="input" />
                    <span class="label">Savvy Apps</span>
                  </label>
                  <label class="input-wrap">
                    <input v-model.trim="signupForm.title" type="text" class="input" />
                    <span class="label">Company</span>
                  </label>
                  <label class="input-wrap">
                    <input v-model.trim="signupForm.email" type="text" class="input" />
                    <span class="label">you@email.com</span>
                  </label>
                  <label class="input-wrap">
                    <input v-model.trim="signupForm.password" type="password" class="input" />
                    <span class="label">min 6 characters</span>
                  </label>
                  <label class="input-wrap">
                    <input v-model.trim="signupForm.imagePath" type="text" class="input" />
                    <span class="label">Image Url</span>
                  </label>
                  <div class="forgot-pwd">
                    <a @click.prevent="toggleForm()">Back to Log In</a>
                  </div>
                  <div class="error" v-if="errors.length">
                    <div v-for="(error, index) in errors" :key="`error-${index}`" v-html="error"></div>
                  </div>
                  <div class="submit-wrap">
                      <input type="submit" @click="signup()" value="Sign in">
                  </div>
                </form>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery'
import PasswordReset from '@/components/PasswordReset'
export default {
  data() {
    return {
      errors: [],
      loginForm: {
        email: '',
        password:'',
      },
      signupForm: {
        name: '',
        title: '',
        email: '',
        password: '',
        imagePath: ''
      },
      showLoginForm: true,
      showPasswordReset: false
    }
  },
  components: {
    PasswordReset
  },
  computed: {
    showCreateAccount() {
      return this.$route.query.id === 'saroj'
    }
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    login() {
      if(this.validEmail(this.loginForm.email) && this.loginForm.password) {
        this.$store.dispatch('login', {
          email: this.loginForm.email,
          password: this.loginForm.password,
        });
      } else {
        this.errors = []
        this.errors.push('Email or password is invalid')
      }
    },
    signup() {
      if(this.validEmail(this.signupForm.email) && 
         this.signupForm.password &&
         this.signupForm.name &&
         this.signupForm.title &&
         this.signupForm.imagePath) {
        this.$store.dispatch('signup', {
          email: this.signupForm.email,
          password: this.signupForm.password,
          name: this.signupForm.name,
          title: this.signupForm.title,
          image: this.signupForm.imagePath
        })
      } else {
        this.errors = []
        this.errors.push('All Fields are required')
      }
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
  created() {
    $(document).on("focus", ".input", function () {
      $(this).addClass("input-filled");
    });
    $(document).on("blur keyup", ".input", function () {
      if ($(this).val() != '') {
        $(this).addClass("input-filled");
      } else {
        $(this).removeClass("input-filled");
      }
    });
    $('.input').each(function () {
      if($(this).val() != '') {
        $(this).addClass('input-filled');
      }
    });
    // console.log(process.env.VUE_APP_APIKEY)
  }
}
</script>
